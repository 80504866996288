import { useEffect, useState, useMemo } from "react";
import "./ExtractDataPage.css";
import { fetchFromAPI, fetchFromAPIWithObj } from "../../utils/hooks.js";
import Datatable from "../Datatable.js";
import { Link } from "react-router-dom";
import { PAGE } from "../../utils/globals";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import surveyTheme from "survey-core/themes/plain-light-panelless";
import ExtractQuestions from "../../survey/extractQuestions.js";
import Loader from "../Loader.js";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function ExtractDataPage() {
  const [isLoading, setLoading] = useState(true);

  const [countData, setCountData] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const [isCatchLevel, setIsCatchLevel] = useState(false);
  const [surveyConfigData, setSurveyConfigData] = useState(null);

  useEffect(() => {
    fetchFromAPI("FilterOptions")
      .then((FilterOptions) => {
        if (FilterOptions.success) {
          setFilterOptions(FilterOptions);
        }
        setLoading(false);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  const survey = useMemo(() => {
    const surveyJson = ExtractQuestions({
      data: filterOptions,
    });
    return new Model(surveyJson);
  }, [filterOptions]);

  survey.applyTheme(surveyTheme);
  survey.showNavigationButtons = false;

  survey.onValueChanged.add((_, options) => {
    if (options.question.name === "SelectAll") {
      const isSelectAll = options.value.length;

      survey.getPanelByName("Extract").questions.forEach((q) => {
        if (q.getType() === "checkbox" && q.name !== "SelectAll") {
          isSelectAll ? q.selectAll() : q.clearValue();
        }
      });
    }
  });

  const submitSurvey = () => {
    setResponseData(null);
    let surveyData = survey
      .getPlainData()
      .filter((item) => !["dateToggle"].includes(item.name))
      .map(({ title, displayValue, isNode, data, ...rest }) => rest);

    const columnFilters = surveyData.find(
      (item) => item.name === "ColumnFilters"
    );

    if (columnFilters.value) {
      const transformedData = Object.entries(columnFilters.value).map(
        ([name, value]) => {
          const colValue = value.Value;
          if (colValue.length === 1) {
            return { name, value: colValue[0] };
          } else {
            return { name };
          }
        }
      );
      surveyData = surveyData.concat(transformedData);
    }
    surveyData = surveyData.filter(
      (item) => !["ColumnFilters"].includes(item.name)
    );

    const isCatchLevelExtract = surveyData.find(
      (item) => item.name === "CatchLevelExtract"
    ).value;

    const selectedCols = surveyData
      .filter((item) =>
        [
          "TripData",
          "Satisfaction",
          "SurveyQuestions",
          "DemographicInformation",
          "DataNotEnteredByAngler",
          isCatchLevelExtract && "CatchData",
          isCatchLevelExtract && "SpeciesDetails",
        ].includes(item.name)
      )
      .map((item) => item.value)
      .flat();

    console.log("Selected Columns", selectedCols);

    surveyData.push({
      name: "SelectedColumns",
      value: selectedCols,
    });

    surveyData = surveyData.filter(
      (item) =>
        ![
          "CatchLevelExtract",
          "SelectAll",
          "TripData",
          "Satisfaction",
          "SurveyQuestions",
          "DemographicInformation",
          "DataNotEnteredByAngler",
          "CatchData",
          "SpeciesDetails",
        ].includes(item.name)
    );

    if (survey.validate(true, true)) {
      setLoading(true);
      setSurveyConfigData(surveyData);
      setIsCatchLevel(isCatchLevelExtract);
      fetchFromAPIWithObj(
        isCatchLevelExtract ? "ExtractFishCount" : "ExtractSurveyCount",
        surveyData
      ).then((response) => {
        setCountData(response.result.record_count);
        setLoading(false);
      });
    }
  };

  const submitSurveyConfigData = () => {
    setLoading(true);

    fetchFromAPIWithObj(
      isCatchLevel ? "ExtractFish" : "ExtractSurvey",
      surveyConfigData
    ).then((response) => {
      setResponseData(response.result.data);
      setLoading(false);
    });
  };

  return (
    <div className="section collapsed">
      <div className="container">
        <ol className="breadcrumb">
          <li>
            <Link to={PAGE.HOME.URL}>{PAGE.HOME.TITLE}</Link>
          </li>
          <li className="active">{PAGE.EXTRACT_DATA.TITLE}</li>
        </ol>
        <div className="page-title">
          <h1>CIRAS - {PAGE.EXTRACT_DATA.TITLE}</h1>
          {!filterOptions ? (
            <Loader />
          ) : (
            <>
              <Survey model={survey} showCompletedPage={false} />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end
"
              >
                <Button variant="contained" onClick={submitSurvey}>
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    survey.clear();
                    survey.render();
                  }}
                >
                  Clear
                </Button>
              </Stack>
              {isLoading ? (
                <>
                  <Loader />
                  {countData > 40000 && (
                    <Typography
                      variant="h6"
                      textAlign={"center"}
                      paddingBottom={4}
                    >
                      Please note: Retrieving larger amounts of records may take
                      some time.
                    </Typography>
                  )}
                </>
              ) : responseData ? (
                <>
                  <Datatable
                    data={responseData}
                    page={PAGE.EXTRACT_DATA.URL}
                    exportButtons={true}
                  />
                </>
              ) : (
                <Stack spacing={4} paddingTop={3} paddingBottom={3}>
                  <h3>Results: {countData.toLocaleString()}</h3>
                  {countData > 0 && (
                    <Button variant="outlined" onClick={submitSurveyConfigData}>
                      Proceed
                    </Button>
                  )}
                </Stack>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExtractDataPage;
