import StateTemplateHeader from "./components/StateTemplateHeader";
import StateTemplateFooter from "./components/StateTemplateFooter";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/main/HomePage";
import QualityControlPage from "./components/main/QualityControlPage";
import SurveyDetailPage from "./components/main/SurveyDetailPage";
import SearchViewSurveysPage from "./components/main/SearchViewSurveysPage";
import ExtractDataPage from "./components/main/ExtractDataPage";
import { PAGE } from "./utils/globals";
import { Logout } from "./components/msal/Logout";
import { MsalProvider } from "@azure/msal-react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import "./App.css";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { isAdminWrite, hasUserRoles } from "./utils/hooks.js";

function App({ pca }) {
  return (
    <MsalProvider instance={pca}>
      <Router>
        <div className="App">
          <StateTemplateHeader />
          <div id="main-content" className="main-content">
            <main className="main-primary">
              <AuthenticatedTemplate>
                {hasUserRoles() ? (
                  <Pages />
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Stack paddingTop={20}>
                      <h1>
                        Your account does not have the appropriate access to use
                        the application.
                      </h1>
                    </Stack>
                  </Box>
                )}
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Box display="flex" justifyContent="center">
                  <Stack paddingTop={20}>
                    <h1>Please login to use this application</h1>
                  </Stack>
                </Box>
              </UnauthenticatedTemplate>
            </main>
          </div>

          <StateTemplateFooter />
        </div>
      </Router>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path={PAGE.QUALITY_CONTROL.URL} element={<QualityControlPage />} />

      <Route
        path={`${PAGE.SEARCH_AND_VIEW_SURVEYS.URL}/:id`}
        element={<SurveyDetailPage />}
      />
      <Route
        path={`${PAGE.EXTRACT_DATA.URL}/:id`}
        element={<SurveyDetailPage />}
      />
      {isAdminWrite() && (
        <Route
          path={`${PAGE.QUALITY_CONTROL.URL}/:id`}
          element={<SurveyDetailPage />}
        />
      )}
      <Route
        path={PAGE.SEARCH_AND_VIEW_SURVEYS.URL}
        element={<SearchViewSurveysPage />}
      />
      <Route path={PAGE.EXTRACT_DATA.URL} element={<ExtractDataPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path={PAGE.HOME.URL} element={<HomePage />} />
    </Routes>
  );
}

export default App;
