import { Link } from "react-router-dom";
import "./HomePage.css";
import { PAGE } from "../../utils/globals";
import { fetchFromAPI, isAdminWrite } from "../../utils/hooks.js";
import { useEffect, useState } from "react";
import Loader from "../Loader.js";
import Typography from "@mui/material/Typography";

function HomePage() {
  const [isLoading, setLoading] = useState(true);
  const [surveyInfo, setSurveyInfo] = useState(null);

  useEffect(() => {
    fetchFromAPI("InternalLandingPageData")
      .then((response) => {
        console.log("R", response);

        setSurveyInfo(response);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="section collapsed home">
      <div className="container">
        <div className="page-title">
          <h2>California Inland Recreational Angler Survey (CIRAS)</h2>
        </div>
        <div className="home-content">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="data-findings">
              <div className="card card-default">
                <div className="card-body">
                  <Typography component="span" variant="h6" fontWeight={"bold"}>
                    Survey Submission Past 30 Days
                  </Typography>
                  <div className="fish-info">
                    <div className="fish-tile">
                      <span>
                        {surveyInfo.result
                          ?.find((obj) => obj.name === "NewSurveys")
                          .value.toLocaleString()}
                      </span>
                      <p>Surveys Submitted</p>
                    </div>
                    <div className="fish-tile">
                      <span>
                        {surveyInfo.result
                          ?.find((obj) => obj.name === "FishReported")
                          .value.toLocaleString()}
                      </span>
                      <p>Fish Reported</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-default">
                <div className="card-body">
                  <Typography component="span" variant="h6" fontWeight={"bold"}>
                    Database
                  </Typography>
                  <div className="fish-info">
                    <div className="fish-tile">
                      <span>
                        {surveyInfo.result
                          ?.find((obj) => obj.name === "TotalSurveys")
                          .value.toLocaleString()}
                      </span>
                      <p>Total Surveys</p>
                    </div>
                    <div className="fish-tile">
                      <span>
                        {surveyInfo.result
                          ?.find((obj) => obj.name === "PendingSurveys")
                          .value.toLocaleString()}
                      </span>
                      <p>Waiting Approval</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-default">
                <div className="card-body">
                  <Typography component="span" variant="h6" fontWeight={"bold"}>
                    Reporting Database
                  </Typography>
                  <div className="fish-info">
                    <div className="fish-tile">
                      <span>
                        {surveyInfo.result
                          ?.find((obj) => obj.name === "TotalValidatedSurveys")
                          .value.toLocaleString()}
                      </span>
                      <p>Total Surveys</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isAdminWrite() && (
            <Link
              className="btn ciras-nav btn-lg btn-primary rounded-15"
              to={PAGE.QUALITY_CONTROL.URL}
            >
              <span
                className="ca-gov-icon-check-list"
                aria-hidden="true"
              ></span>{" "}
              {PAGE.QUALITY_CONTROL.TITLE}
            </Link>
          )}
          <Link
            className="btn ciras-nav btn-lg btn-primary rounded-15"
            to={PAGE.SEARCH_AND_VIEW_SURVEYS.URL}
          >
            <span className="ca-gov-icon-searching" aria-hidden="true"></span>{" "}
            {PAGE.SEARCH_AND_VIEW_SURVEYS.TITLE}
          </Link>

          <Link
            className="btn ciras-nav btn-lg btn-primary rounded-15"
            to={PAGE.EXTRACT_DATA.URL}
          >
            <span className="ca-gov-icon-download" aria-hidden="true"></span>{" "}
            {PAGE.EXTRACT_DATA.TITLE}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
