// MapComponent.js
import React, { useRef, useEffect } from "react";
import MapView from "@arcgis/core/views/MapView.js";
import Map from "@arcgis/core/Map.js";
import Graphic from "@arcgis/core/Graphic.js";
import "@arcgis/core/assets/esri/themes/light/main.css";
import WebStyleSymbol from "@arcgis/core/symbols/WebStyleSymbol.js";
import Basemap from "@arcgis/core/Basemap.js";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery.js";
import Expand from "@arcgis/core/widgets/Expand.js";

const MapComponent = (props) => {
  const mapRef = useRef(null);

  const selectedLocation = props.selectedLocation;
  const gelocationPicker = props.geolocationPicker;
  const onChange = props.onChange;
  const basemapIndex = props.basemapIndex;
  const setBasemapIndex = props.setBasemapIndex;

  useEffect(() => {
    if (!mapRef.current || !selectedLocation) return;

    var basemaps = [
      Basemap.fromId("topo-vector"),
      Basemap.fromId("hybrid"),
      Basemap.fromId("streets-navigation-vector"),
    ];

    let curBasemap;
    switch (basemapIndex) {
      case 0:
        curBasemap = "topo-vector";
        break;
      case 1:
        curBasemap = "hybrid";
        break;
      case 2:
        curBasemap = "streets-navigation-vector";
        break;
      default: // default to topo-vector
        curBasemap = "topo-vector";
    }

    // initialize the map with topo-vector basemap if not specified
    const map = new Map({
      basemap: curBasemap,
    });

    const view = new MapView({
      container: mapRef.current,
      map: map,
      center: [selectedLocation.longitude, selectedLocation.latitude],
      zoom: 13,
      // spatialReference: viewSpatialReference,
    });

    const basemapGallary = new BasemapGallery({
      view: view,
      source: basemaps, // autocasts to LocalBasemapsSource
    });

    const bgExpand = new Expand({
      view,
      content: basemapGallary,
      expandIcon: "basemap",
    });
    view.ui.add(bgExpand, "top-right");

    map.watch("basemap", (newBasemap) => {
      setBasemapIndex(basemaps.indexOf(newBasemap));
      bgExpand.toggle();
    });

    if (gelocationPicker) {
      createGraphic(selectedLocation, true);
    }

    function createGraphic(location, selected) {
      console.log("createGraphic", location);
      // First create a point geometry
      var point = {
        type: "point", // autocasts as new Point()
        latitude: location.latitude,
        longitude: location.longitude,
      };

      // Create a symbol for drawing the point
      var markerSymbol = new WebStyleSymbol({
        name: "tear-pin-1",
        styleName: "Esri2DPointSymbolsStyle",
      });

      // Create a graphic and add the geometry and symbol to it
      var pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol,
        attributes: { text: location.text, value: location.value },
      });

      // Add the graphics to the view's graphics layer
      view.graphics.add(pointGraphic);
    }
    return () => view && view.destroy();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(selectedLocation),
    /* eslint-enable react-hooks/exhaustive-deps */
    selectedLocation,
    gelocationPicker,
    onChange,
    setBasemapIndex,
  ]);

  return (
    <>
      <div
        ref={mapRef}
        style={{ padding: "0", margin: "0", height: "300px", width: "100%" }}
      />
      Latitude: {selectedLocation.latitude} <br />
      Longitude: {selectedLocation.longitude}
    </>
  );
};

export default MapComponent;
