import React, { Component } from "react";
import logo from "../images/Ca-Gov-Logo-Gold.svg";
import "./StateTemplateHeader.css";
import headerLogo from "../images/CIRAS-Logo_Final_for-LeftCornerWeb-ver2-03.jpg";
import { Link } from "react-router-dom";
import { PAGE } from "../utils/globals";
import WelcomeName from "./msal/WelcomeName";
import SignInSignOutButton from "./msal/SignInSignOutButton";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { isAdminWrite } from "../utils/hooks";
import config from "../config.js";

class StateTemplateHeader extends Component {

  constructor() {
    super();

    this.state = {
      isEnvWarningDisplayed : (config.STAGE !== "PROD" )
    };
  }  


  render() {
    return (
      <header role="banner" id="header" className="global-header">
        <div id="skip-to-content">
          <a href="#main-content">Skip to Main Content</a>
        </div>

        {
          this.state.isEnvWarningDisplayed &&

          <div className="alert alert-warning alert-dismissible alert-banner" role="alert">
            <div className="container">

                <span className="alert-level">
                  <span className="ca-gov-icon-important" aria-hidden="true"></span>
                  Warning! This is the {config.STAGE_NAME} environment! 
                </span>
                <span className="alert-text">This is not a production system and is for testing purposes only. All data entered may be lost at any time.</span> 
            </div>
          </div>
      }


        <div className="utility-header">
          <div className="container">
            <div className="group flex-row">
              <div className="social-media-links">
                <div className="header-cagov-logo">
                  <a href="https://ca.gov">
                    <span className="sr-only">CA.gov</span>
                    <img
                      src={logo}
                      className="pos-rel"
                      alt=""
                      aria-hidden="true"
                    />
                  </a>
                </div>
                <div className="settings-links">
                  <a href="https://wildlife.ca.gov">
                    <span className="ca-gov-icon-home"></span> CDFW Home
                  </a>
                </div>
              </div>
              <div className="settings-links">
                <WelcomeName />
                <SignInSignOutButton />
              </div>
            </div>
          </div>
        </div>
        <div
          className="language-settings section section-default collapse collapsed"
          role="alert"
          id="languageBar"
        >
          <div className="container p-y">
            <button
              type="button"
              className="close"
              data-toggle="collapse"
              data-target="#languageBar"
              aria-expanded="false"
              aria-controls="searchBar"
              aria-label="Close"
            >
              <span
                className="ca-gov-icon-close-mark"
                aria-hidden="true"
              ></span>
            </button>

            <div id="google_translate_element"></div>
          </div>
        </div>

        <AuthenticatedTemplate>
          <div className="branding">
            <div className="header-organization-banner">
              <Link to={PAGE.HOME.URL}>
                <img
                  src={headerLogo}
                  alt="California Inland Recreational Angler Survey"
                />
              </Link>
            </div>
          </div>

          <div className="mobile-controls">
            <span className="mobile-control-group mobile-header-icons"></span>
            <div className="mobile-control-group main-nav-icons">
              <button
                id="nav-icon3"
                className="mobile-control toggle-menu"
                aria-expanded="false"
                aria-controls="navigation"
                data-toggle="collapse"
                data-target="#navigation"
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className="sr-only">Menu</span>
              </button>
            </div>
          </div>
        </AuthenticatedTemplate>
        <div className="navigation-search">
          <AuthenticatedTemplate>
            <nav
              id="navigation"
              className="main-navigation dropdown hidden-print"
            >
              <ul id="nav_list" className="top-level-nav">
                <li
                  className="nav-item  menu-item menu-item-type-post_type menu-item-object-page"
                  title=""
                >
                  <Link className="first-level-link" to={PAGE.HOME.URL}>
                    <span className="ca-gov-icon-home" />
                    <span className="link-title">{PAGE.HOME.TITLE}</span>
                  </Link>
                </li>
                {isAdminWrite() && (
                  <li
                    className="nav-item  menu-item menu-item-type-post_type menu-item-object-page"
                    title=""
                  >
                    <Link
                      className="first-level-link"
                      to={PAGE.QUALITY_CONTROL.URL}
                    >
                      <span className="ca-gov-icon-check-list" />
                      <span className="link-title">
                        {PAGE.QUALITY_CONTROL.TITLE}
                      </span>
                    </Link>
                  </li>
                )}
                <li
                  className="nav-item  menu-item menu-item-type-post_type menu-item-object-page"
                  title=""
                >
                  <Link
                    className="first-level-link"
                    to={PAGE.SEARCH_AND_VIEW_SURVEYS.URL}
                  >
                    <span className="ca-gov-icon-searching" />
                    <span className="link-title">
                      {PAGE.SEARCH_AND_VIEW_SURVEYS.TITLE}
                    </span>
                  </Link>
                </li>
                <li
                  className="nav-item  menu-item menu-item-type-post_type menu-item-object-page"
                  title=""
                >
                  <Link className="first-level-link" to={PAGE.EXTRACT_DATA.URL}>
                    <span className="ca-gov-icon-download" />
                    <span className="link-title">
                      {PAGE.EXTRACT_DATA.TITLE}
                    </span>
                  </Link>
                </li>
              </ul>
            </nav>
          </AuthenticatedTemplate>
          <div id="head-search" />
        </div>
        <div className="header-decoration" />
      </header>
    );
  }
}

export default StateTemplateHeader;
