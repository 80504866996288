export default function ExtractQuestions(props) {
  let extractQuestionsJson = {};
  console.log("Extract Questions", props.data);
  if (props.data) {
    extractQuestionsJson = {
      pages: [
        {
          name: "FiltersPage",
          elements: [
            {
              type: "panel",
              name: "Filters",
              title: "Filters",
              state: "expanded",
              width: "100%",
              minWidth: "256px",
              questionTitleLocation: "left",
              elements: [
                {
                  type: "panel",
                  width: "60%",
                  minWidth: "256px",
                  questionTitleLocation: "left",

                  elements: [
                    {
                      type: "boolean",
                      name: "dateToggle",
                      title: "Search By:",
                      defaultValue: "false",
                      labelTrue: "Start Date",
                      labelFalse: "Year",
                    },
                    {
                      type: "tagbox",
                      visibleIf: "{dateToggle} = false",
                      name: "Year",
                      title: "Year",
                      choices: props.data.result.YearChoice,
                    },
                    {
                      type: "text",
                      visibleIf: "{dateToggle} = true",
                      name: "StartDate",
                      title: "Start Date",
                      inputType: "date",
                    },
                    {
                      type: "text",
                      visibleIf: "{dateToggle} = true",
                      name: "EndDate",
                      title: "End Date",
                      inputType: "date",
                      startWithNewLine: false,
                    },
                    {
                      type: "tagbox",
                      name: "Region",
                      title: "Region",
                      choices: props.data.result.Region,
                    },
                    {
                      type: "tagbox",
                      name: "WaterBody",
                      title: "Water Name / DFG Water ID",
                      choices: props.data.result.Water,
                    },
                    {
                      type: "tagbox",
                      name: "Species",
                      title: "Species/Species Group",
                      choices: props.data.result.Species,
                    },
                  ],
                },
                {
                  type: "matrixdropdown",
                  name: "ColumnFilters",
                  titleLocation: "hidden",
                  width: "40%",
                  minWidth: "256px",
                  startWithNewLine: false,
                  columns: [
                    {
                      name: "Value",
                    },
                  ],
                  choices: [
                    {
                      value: "true",
                      text: "Yes",
                    },
                    {
                      value: "false",
                      text: "No",
                    },
                  ],
                  cellType: "checkbox",
                  rows: [
                    {
                      value: "FishCaught",
                      text: "Fish Caught",
                    },
                    {
                      value: "SupplementalQuestions",
                      text: "Contains Answers to Supplemental Questions",
                    },
                    {
                      value: "FishingInTheCity",
                      text: "Adaptive Waters Fishing in the City",
                    },
                    {
                      value: "AnadromousWaters",
                      text: "Anadromous Waters",
                    },
                    {
                      value: "FishKept",
                      text: "Fish Kept",
                    },
                    {
                      value: "CIRASData",
                      text: "CIRAS Data",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Extract",
          elements: [
            {
              type: "panel",
              name: "Extract",
              title: "Extract",
              state: "expanded",
              width: "100%",
              minWidth: "256px",
              elements: [
                {
                  type: "boolean",
                  name: "CatchLevelExtract",
                  titleLocation: "hidden",
                  defaultValue: "false",
                  labelTrue: "Catch Level Extract",
                  labelFalse: "Survey Level Extract",
                },
                {
                  type: "checkbox",
                  name: "SelectAll",
                  titleLocation: "hidden",
                  startWithNewLine: false,
                  choices: ["Select All"],
                },
                {
                  type: "checkbox",
                  name: "TripData",
                  title: "Trip Data",
                  choices: [
                    "Water Name",
                    "Map Pin",
                    "Fished From",
                    "Species Targeted",
                    {
                      value: "# of Fish Caught",
                      visibleIf: "{CatchLevelExtract} = false",
                    },
                    "Date Fished",
                    "# of Hours Fished",
                    "Start Time",
                    "Gear Types",
                    "Second Rod",
                  ],
                  colCount: 5,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "CatchData",
                  visibleIf: "{CatchLevelExtract} = true",
                  title: "Catch Data",
                  choices: ["Size", "Kept/Released"],
                  colCount: 3,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "SpeciesDetails",
                  visibleIf: "{CatchLevelExtract} = true",
                  title: "Fin Clip or Tagged",
                  choices: ["Fin Clip", "Tagged"],
                  colCount: 3,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "Satisfaction",
                  choices: [
                    "Overall Angling Experience",
                    "Size of Fish",
                    "# of Fish",
                    "Comments",
                  ],
                  colCount: 5,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "SurveyQuestions",
                  title: "Adaptive Survey Questions",
                  choices: ["Fishing in the City Adaptive Questions"],
                  colCount: 2,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "DemographicInformation",
                  title: "Demographic Information",
                  choices: [
                    "GOID",
                    "Age",
                    "Gender",
                    "Race/Ethnicity",
                    "Primary Language",
                    "Accessibility Improvement",
                    "Zip Code",
                  ],
                  colCount: 5,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
                {
                  type: "checkbox",
                  name: "DataNotEnteredByAngler",
                  title: "Data Not Entered by Angler",
                  choices: [
                    "Submission Date",
                    "Device Used",
                    "Survey Duration",
                  ],
                  colCount: 5,
                  showSelectAllItem: true,
                  separateSpecialChoices: true,
                  selectAllText: "Select Group",
                },
              ],
            },
          ],
        },
      ],
      showQuestionNumbers: false,
      questionsOnPageMode: "singlePage",
      widthMode: "responsive",
    };
  }
  return extractQuestionsJson;
}
