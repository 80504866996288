import { createElement } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import {
  SurveyQuestionElementBase,
  ReactQuestionFactory,
} from "survey-react-ui";
import MapComponent from "../components/MapComponent";

const CUSTOM_TYPE = "location-picker";

class QuestionLocationPickerModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }
  get locationPickerType() {
    return this.getPropertyValue("locationPickerType");
  }
  set locationPickerType(val) {
    this.setPropertyValue("locationPickerType", val);
  }

  get selectedLocation() {
    return this.getPropertyValue("selectedLocation");
  }
  set selectedLocation(val) {
    this.setPropertyValue("selectedLocation", val);
  }

  get geolocationPicker() {
    return this.getPropertyValue("geolocationPicker");
  }
  set geolocationPicker(val) {
    this.setPropertyValue("geolocationPicker", val);
  }

  get basemapIndex() {
    return this.getPropertyValue("basemapIndex");
  }
  set basemapIndex(val) {
    this.setPropertyValue("basemapIndex", val);
  }
}

// Register `QuestionLocationPickerModel` as a model for the `location-picker` type
export function registerLocationPicker() {
  ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionLocationPickerModel(name);
  });
}

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [
    {
      name: "selectedLocation",
    },
    {
      name: "geolocationPicker",
    },
    {
      name: "basemapIndex",
    },
  ],
  function () {
    return new QuestionLocationPickerModel();
  },
  "question"
);

class SurveyQuestionLocationPicker extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = {
      value: this.question.value,
    };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get selectedLocation() {
    return this.question.selectedLocation;
  }

  get geolocationPicker() {
    return this.question.geolocationPicker;
  }
  get basemapIndex() {
    return this.question.basemapIndex;
  }
  get type() {
    return this.question.geolocationPickerType;
  }

  handleLocationChange = (data) => {
    this.question.value = data;
  };

  setBasemapIndex = (index) => {
    this.question.basemapIndex = index;
  };

  renderLocationPicker() {
    return (
      <>
        <MapComponent
          selectedLocation={this.selectedLocation}
          onChange={this.handleLocationChange}
          setBasemapIndex={this.setBasemapIndex}
          basemapIndex={this.basemapIndex}
          geolocationPicker={this.geolocationPicker}
        />
      </>
    );
  }

  renderElement() {
    return <div style={this.style}>{this.renderLocationPicker(this.type)}</div>;
  }
}

// Register `SurveyQuestionColorPicker` as a class that renders `color-picker` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionLocationPicker, props);
});
