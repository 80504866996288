export const PAGE = {
  HOME: {
    URL: "/",
    TITLE: "Home",
  },
  QUALITY_CONTROL: {
    URL: "/quality-control",
    TITLE: "Quality Control",
  },
  SEARCH_AND_VIEW_SURVEYS: {
    URL: "/search-view-surveys",
    TITLE: "Search and View Surveys",
  },
  EXTRACT_DATA: {
    URL: "/extract-data",
    TITLE: "Extract Data",
  },
};
