export default function SurveyQuestions(props) {
  let surveyQuestionsJson = {};

  console.log("Survey Questions", props.data);

  if (props.data) {
    surveyQuestionsJson = {
      pages: [
        {
          title: "QC",
          elements: [
            {
              name: "QC",
              type: "matrixdynamic",
              titleLocation: "hidden",
              startWithNewLine: false,
              readOnly: true,
              columns: [
                {
                  name: "qc_flag_text",
                  title: "Flag",
                  cellType: "text",
                },
              ],
            },
          ],
        },
        {
          title: "Missing Water Body",
          visible: props.data.missing_water,
          elements: [
            {
              title: "Location Name",
              name: "LocationName",
              type: "text",
              readOnly: true,
            },
            {
              title: "County",
              name: "MissingCounty",
              type: "text",
              readOnly: true,
            },
            {
              title: "Location Description",
              name: "MissingDescription",
              type: "comment",
              readOnly: true,
              startWithNewLine: false,
            },
            {
              title: "Phone Number",
              name: "PhoneNumber",
              type: "text",
              readOnly: true,
            },
            {
              title: "Angler Name",
              name: "AnglerName",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
            },
            {
              title: "Angler Phone",
              name: "PhoneNumber",
              type: "text",
              readOnly: true,
            },
            {
              title: "Angler Email",
              name: "AnglerEmail",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
            },
          ],
        },
        {
          title: "Location and Date",
          elements: [
            {
              type: "panel",
              name: "Filters",
              titleLocation: "hidden",
              width: "66%",
              minWidth: "256px",
              elements: [
                {
                  title: "Location",
                  name: "water_name",
                  type: "dropdown",
                  choices: props.waterChoices,
                  readOnly: true,
                  isRequired: props.waterChoices,
                  choicesLazyLoadEnabled: true,
                  choicesLazyLoadPageSize: 40,
                },
                {
                  title: "Fished From",
                  name: "FishedFrom",
                  type: "text",
                  readOnly: true,
                  startWithNewLine: false,
                },
                {
                  title: "Date Fished",
                  name: "date_fished",
                  type: "text",
                  readOnly: true,
                },
                {
                  title: "# of Hours",
                  name: "number_of_hours_fished",
                  type: "text",
                  readOnly: true,
                  startWithNewLine: false,
                },
                {
                  title: "Start of fishing day",
                  name: "start_of_fishing_day",
                  type: "text",
                  readOnly: true,
                },
                {
                  title: "Angler Zip Code",
                  name: "angler_zip_code",
                  type: "text",
                  readOnly: true,
                  startWithNewLine: false,
                },
                {
                  title: "Device Used",
                  name: "device_used",
                  type: "text",
                  readOnly: true,
                },
                {
                  title: "Survey Duration",
                  name: "survey_duration",
                  type: "text",
                  readOnly: true,
                  startWithNewLine: false,
                },
                {
                  title: "Submission Date",
                  name: "submission_date",
                  type: "text",
                  readOnly: true,
                  startWithNewLine: false,
                },
              ],
            },
            props.data.hasOwnProperty("latitude") && {
              type: "location-picker",
              name: "geolocation",
              title: "Map Location",
              geolocationPicker: true,
              startWithNewLine: false,
              selectedLocation: {
                latitude: props.data.latitude,
                longitude: props.data.longitude,
              },
            },
          ],
        },
        {
          title: "Fish Species",
          elements: [
            {
              name: "Survey_Species_Targeted",
              title: "Species Targeted",
              type: "matrixdynamic",
              visible: props.data.hasOwnProperty("Survey_Species_Targeted"),
              readOnly: true,
              columns: [
                {
                  name: "species_name",
                  title: "Species",
                  cellType: "text",
                },
              ],
            },
            {
              title: "Did you catch fish?",
              name: "fish_caught",
              type: "boolean",
              maxWidth: "10%",
              readOnly: true,
              startWithNewLine: false,
            },
            {
              title: "Caught Fish",
              name: "Caught_Fish",
              visible: props.data.hasOwnProperty("Caught_Fish"),
              type: "matrixdynamic",
              readOnly: true,
              allowAddRows: false,
              columns: [
                {
                  name: "species_name",
                  title: "Species",
                  cellType: "text",
                  width: "100%",
                  readOnly: true,
                },
                {
                  name: "length_in_inches",
                  title: "Length (inches)",
                  cellType: "text",
                  readOnly: true,
                },
                {
                  name: "kept",
                  title: "Kept",
                  cellType: "boolean",
                  renderAs: "checkbox",
                  readOnly: true,
                },
                {
                  name: "adipose",
                  title: "Adipose",
                  visible:
                    props.data.Caught_Fish &&
                    props.data.Caught_Fish.some((item) =>
                      item.hasOwnProperty("fish_additional_info")
                    ),
                  cellType: "text",
                  readOnly: true,
                },
                {
                  name: "tagDetail",
                  title: "Tag Detail",
                  visible:
                    props.data.Caught_Fish &&
                    props.data.Caught_Fish.some((item) =>
                      item.hasOwnProperty("fish_additional_info")
                    ),
                  cellType: "text",
                  readOnly: true,
                },
                {
                  name: "tagNumber",
                  title: "Tag Number",
                  visible:
                    props.data.Caught_Fish &&
                    props.data.Caught_Fish.some((item) =>
                      item.hasOwnProperty("fish_additional_text")
                    ),
                  cellType: "text",
                  readOnly: true,
                },
              ],
            },
          ],
        },
        {
          title: "Gear",
          elements: [
            {
              type: "checkbox",
              name: "Gear",
              title: "Gear Used",
              readOnly: true,
              choices: ["Bait", "Fly", "Lure", "Other"],
              colCount: 4,
            },
            {
              name: "GearOther",
              title: "Gear Other Value",
              visibleIf: "{Gear} contains 'Other'",
              readOnly: true,
              type: "text",
              startWithNewLine: false,
            },
            {
              title: "Used Second Rod",
              name: "used_second_rod",
              type: "boolean",
              renderAs: "checkbox",
              readOnly: true,
              startWithNewLine: false,
            },
          ],
        },
        {
          title: "Survey",
          elements: [
            {
              type: "matrix",
              name: "Quality",
              readOnly: true,
              titleLocation: "hidden",
              columns: [
                {
                  value: 5,
                  text: "Very Satisfied",
                },
                {
                  value: 4,
                  text: "Somewhat Satisfied",
                },
                {
                  value: 3,
                  text: "Neutral",
                },
                {
                  value: 2,
                  text: "Somewhat Unsatisfied",
                },
                {
                  value: 1,
                  text: "Very Unsatisfied",
                },
              ],
              rows: [
                {
                  value: "Overall",
                  text: "Overall Angling Experience",
                },
                {
                  value: "Size",
                  text: "Size of Fish",
                },
                {
                  value: "Number",
                  text: "Number of Fish",
                },
              ],
              alternateRows: true,
              isAllRowRequired: true,
            },
            {
              name: "Comments",
              type: "comment",
              readOnly: true,
              title: "Additional comments about this fishing trip",
            },
          ],
        },
        {
          title: "Adaptive Questions",
          visible:
            props.data.Answers &&
            props.data.Answers.some(
              (answer) =>
                answer.question_code === "SQ" && answer.answer === "Yes"
            ),
          elements: [
            {
              name: "Cooking",
              type: "text",
              readOnly: true,
              title: "Did you or do you plan to cook or eat your catch?",
            },
            {
              name: "Condition",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "What was the condition of your fishing location?",
            },
            {
              name: "CompASB",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title:
                "Have you completed a CDFW Angler Survey Box (ASB) survey before?",
            },
            {
              name: "Travel",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "How did you travel on this trip?",
            },
            {
              name: "TravelOther",
              visibleIf: "{Travel} = 'Other'",
              type: "text",
              title: "Travel Other Value",
              readOnly: true,
              startWithNewLine: false,
            },
          ],
        },
        {
          title: "About the Angler",
          visible:
            props.data.Answers &&
            props.data.Answers.some(
              (answer) =>
                answer.question_code === "SQ" && answer.answer === "Yes"
            ),
          elements: [
            {
              name: "GOID",
              type: "text",
              readOnly: true,
            },
            {
              name: "Age",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
            },
            {
              name: "Gender",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
            },
            {
              name: "GenderOther",
              visibleIf: "{Gender} = 'Other'",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "Gender Other Value",
            },
            {
              name: "Race",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "Race/Ethnicity",
            },
            {
              name: "RaceOther",
              visibleIf: "{Race} = 'Other'",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "Race/Ethnicity Other Value",
            },
            {
              name: "Language",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "Primary Language Spoken at home",
            },
            {
              name: "LanguageOther",
              visibleIf: "{Language} = 'Other'",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title: "Language Other Value",
            },
            {
              name: "AccessImprove",
              type: "text",
              readOnly: true,
              startWithNewLine: false,
              title:
                "Which accessibility improvement would you like to see CDFW direct resources towards?",
            },
          ],
        },
      ],
      showQuestionNumbers: false,
      questionsOnPageMode: "singlePage",
      widthMode: "responsive",
    };
  }
  return surveyQuestionsJson;
}
