import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const WelcomeName = () => {
  const { instance } = useMsal();
  const [name, setName] = useState(null);

  const activeAccount = instance.getActiveAccount();
  useEffect(() => {
    if (activeAccount) {
      setName(activeAccount.name?.split("@")[0]?.split(",")[1]);
    } else {
      setName(null);
    }
  }, [activeAccount]);

  if (name) {
    return <div>Welcome, {name}</div>;
  } else {
    return null;
  }
};

export default WelcomeName;
