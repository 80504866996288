import { useEffect, useState } from "react";
import { fetchFromAPI } from "../../utils/hooks.js";
import Datatable from "../Datatable.js";
import { Link } from "react-router-dom";
import { PAGE } from "../../utils/globals";
import Loader from "../Loader.js";

function QualityControlPage() {
  const [isLoading, setLoading] = useState(true);
  const [responseConfig, setResponseConfig] = useState(null);

  useEffect(() => {
    fetchFromAPI("QCLanding")
      .then((response) => {
        setResponseConfig(response.result);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="section collapsed">
      <div className="container">
        <ol className="breadcrumb">
          <li>
            <Link to={PAGE.HOME.URL}>{PAGE.HOME.TITLE}</Link>
          </li>
          <li className="active">{PAGE.QUALITY_CONTROL.TITLE}</li>
        </ol>
        <div className="page-title">
          <h1>CIRAS - {PAGE.QUALITY_CONTROL.TITLE}</h1>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Datatable
                data={responseConfig?.data}
                page={PAGE.QUALITY_CONTROL.URL}
                searchBuilder={true}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QualityControlPage;
