import React, { Component } from "react";

class StateTemplateFooter extends Component {
  render() {
    return (
      <footer id="footer" className="global-footer hidden-print">
        <div className="container footer-menu">
          <div className="row">
            <div className="col-md-3">
              <ul className="footer-links">
                <li>
                  <a href="#skip-to-content">Back to Top</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            Copyright &copy; {new Date().getFullYear()} State of California
          </div>
        </div>
      </footer>
    );
  }
}

export default StateTemplateFooter;
