export default function FilterQuestions(props) {
  let filterQuestionsJson = {};
  console.log("FilterQuestions", props.data);
  if (props.data) {
    filterQuestionsJson = {
      pages: [
        {
          name: "Filters",
          elements: [
            {
              type: "panel",
              name: "Filters",
              title: "Filters",
              state: "expanded",
              questionTitleLocation: "left",

              elements: [
                {
                  type: "panel",

                  questionTitleLocation: "left",

                  elements: [
                    {
                      type: "boolean",
                      name: "dateToggle",
                      title: "Search By:",
                      defaultValue: "false",
                      labelTrue: "Start Date",
                      labelFalse: "Year",
                    },
                    {
                      type: "tagbox",
                      visibleIf: "{dateToggle} = false",
                      name: "Year",
                      title: "Year",
                      choices: props.data.result.YearChoice,
                    },
                    {
                      type: "text",
                      visibleIf: "{dateToggle} = true",
                      name: "StartDate",
                      title: "Start Date",
                      inputType: "date",
                    },
                    {
                      type: "text",
                      visibleIf: "{dateToggle} = true",
                      name: "EndDate",
                      title: "End Date",
                      inputType: "date",
                      startWithNewLine: false,
                    },
                    {
                      type: "tagbox",
                      name: "Region",
                      title: "Region",
                      choices: props.data.result.Region,
                    },
                    {
                      type: "tagbox",
                      name: "WaterBody",
                      title: "Water Name / DFG Water ID",
                      choices: props.data.result.Water,
                    },
                    {
                      type: "tagbox",
                      name: "Species",
                      title: "Species/Species Group",
                      choices: props.data.result.Species,
                    },
                  ],
                },
                {
                  type: "matrixdropdown",
                  name: "ColumnFilters",
                  titleLocation: "hidden",
                  startWithNewLine: false,
                  columns: [
                    {
                      name: "Value",
                    },
                  ],
                  choices: [
                    {
                      value: "true",
                      text: "Yes",
                    },
                    {
                      value: "false",
                      text: "No",
                    },
                  ],
                  cellType: "checkbox",
                  rows: [
                    {
                      value: "FishCaught",
                      text: "Fish Caught",
                    },
                    {
                      value: "SupplementalQuestions",
                      text: "Contains Answers to Supplemental Questions",
                    },
                    {
                      value: "FishingInTheCity",
                      text: "Adaptive Waters Fishing in the City",
                    },
                    {
                      value: "AnadromousWaters",
                      text: "Anadromous Waters",
                    },
                    {
                      value: "FishKept",
                      text: "Fish Kept",
                    },
                    {
                      value: "CIRASData",
                      text: "CIRAS Data",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      showQuestionNumbers: false,
      questionsOnPageMode: "singlePage",
      widthMode: "responsive",
    };
  }
  return filterQuestionsJson;
}
