const dev = {
  CLIENT_ID: "024ef6fb-217f-408b-b5d7-7f60eea7c62f",
  API: "https://aw1-dev-fnapp-ciras-1.azurewebsites.net/api/ExecuteSqlProcedure?code=K03XCu2wWfHBb2OHbCxHo5z1nhxaCRRp1tiFvY7i6GSoAzFuhdceGQ==",
  STAGE: "DEV",
  STAGE_NAME: "development",
};

const test = {
  CLIENT_ID: "42da0062-e9d0-4188-b902-4ffd1cc17cf3",
  API: "https://aw1-test-fnapp-ciras-1.azurewebsites.net/api/ExecuteSqlProcedure?code=xA2msJD6oSf5BNigbyyrlffIuCk58ZXERUZjt4MbTePQAzFuM9tQDg%3D%3D",
  STAGE: "TEST",
  STAGE_NAME: "test",  
};

const prod = {
  CLIENT_ID: "73cd323b-3038-4875-bbfd-b742c1a9e4ec",
  API: "https://aw1-ciras-prod-fnapp.azurewebsites.net/api/ExecuteSqlProcedure?code=s6qZTBwDhXebmVBhxZ9MZyi25p05dDsOGtJ_78dcaWmdAzFuislpSg%3D%3D",
  STAGE: "PROD",
  STAGE_NAME: "production",  
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case "production":
    config = prod;
    break;
  case "test":
    config = test;
    break;
  case "development":
  default:
    config = dev;
}

config.AUTHORITY =
  "https://login.microsoftonline.com/4b633c25-efbf-4006-9f15-07442ba7aa0b";

export default config;
