import React, { useEffect, useRef } from "react";
import "./Datatables.css";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-buttons/js/buttons.print.mjs";
import "datatables.net-searchbuilder-dt";
import "datatables.net-colreorder-dt";
import "datatables.net-datetime";

import moment from "moment";
import * as jzip from "jszip";

window.JSZip = jzip;

const Datatable = ({ data, page, searchBuilder, exportButtons }) => {
  const datatableRef = useRef(null);
  const initialized = useRef(false);

  useEffect(() => {
    console.log("data", data);
    if (initialized.current) {
      return;
    }
    initialized.current = true;

    const columns = [];

    if (data) {
      Object.keys(data[0]).forEach((key) => {
        switch (key) {
          case "fish_caught_id":
            columns.push({
              data: "fish_caught_id",
              title: "Fish Caught ID",
            });
            break;
          case "survey_id":
            columns.push({
              data: "survey_id",
              title: "SV",
              render: function (data, type, row) {
                if (type === "display") {
                  data = `<a href="${page}/${row.survey_id}">${row.survey_version}</a>`;
                }
                return data;
              },
            });
            break;
          case "system_id":
            columns.push({
              data: "system_id",
              title: "ID",
            });
            break;
          case "water_id":
            columns.push({
              data: "water_id",
              title: "Water ID",
            });
            break;
          case "data_source":
            columns.push({
              data: "data_source",
              title: "Data Source",
            });
            break;
          case "county_tag":
            columns.push({
              data: "county_tag",
              title: "County",
            });
            break;
          case "water_name":
            columns.push({
              data: "water_name",
              title: "Water Name",
            });
            break;
          case "qc_flags":
            columns.push({
              data: "qc_flags",
              title: "QC Flags",
            });
            break;
          case "species_caught":
            columns.push({
              data: "species_caught",
              title: "Species",
            });
            break;
          case "region_id":
            columns.push({
              data: "region_id",
              title: "Region",
            });
            break;
          case "latitude":
            columns.push({
              data: "latitude",
              title: "Map Pin (Lat)",
            });
            break;
          case "longitude":
            columns.push({
              data: "longitude",
              title: "Map Pin (Long)",
            });
            break;
          case "FishedFrom":
            columns.push({
              data: "FishedFrom",
              title: "Fished From",
            });
            break;
          case "Survey_Species_Targeted":
            columns.push({
              data: "Survey_Species_Targeted",
              title: "Survey Species Targeted",
            });
            break;
          case "fish_caught":
            columns.push({
              data: "fish_caught",
              title: "Fish Caught",
            });
            break;
          case "supplemental_questions":
            columns.push({
              data: "supplemental_questions",
              title: "SQ",
              render: function (data) {
                return data ? "Yes" : "No";
              },
            });
            break;
          case "fishing_in_the_city":
            columns.push({
              data: "fishing_in_the_city",
              title: "FIC",
              render: function (data) {
                return data ? "Yes" : "No";
              },
            });
            break;
          case "anadromous":
            columns.push({
              data: "anadromous",
              title: "Anadromous Waters",
              render: function (data) {
                return data ? "Yes" : "No";
              },
            });
            break;
          case "date_fished":
            columns.push({
              data: "date_fished",
              title: "Date Fished",
            });
            break;
          case "number_of_hours_fished":
            columns.push({
              data: "number_of_hours_fished",
              title: "Number of Hours Fished",
            });
            break;
          case "start_of_fishing_day":
            columns.push({
              data: "start_of_fishing_day",
              title: "Start Time",
            });
            break;
          case "gear_types":
            columns.push({
              data: "gear_types",
              title: "Gear Types",
            });
            break;
          case "used_second_rod":
            columns.push({
              data: "used_second_rod",
              title: "Second Rod",
            });
            break;
          case "species_name":
            columns.push({
              data: "species_name",
              title: "Species Name",
            });
            break;
          // Length in inches vs Size
          case "length_in_inches":
            columns.push({
              data: "length_in_inches",
              title: "Length (inches)",
            });
            break;
          case "kept":
            columns.push({
              data: "kept",
              title: "Kept",
            });
            break;
          case "FinClip":
            columns.push({
              data: "FinClip",
              title: "Fin Clip",
            });
            break;
          case "Tagged":
            columns.push({
              data: "Tagged",
              title: "Tagged",
            });
            break;
          case "TagNumber":
            columns.push({
              data: "TagNumber",
              title: "Tag Number",
            });
            break;
          case "Overall":
            columns.push({
              data: "Overall",
              title: "Q: Overall Experience",
            });
            break;
          case "Size":
            columns.push({
              data: "Size",
              title: "Q: Size",
            });
            break;
          case "Number":
            columns.push({
              data: "Number",
              title: "Q: Number",
            });
            break;
          case "Comments":
            columns.push({
              data: "Comments",
              title: "Q: Comments",
            });
            break;
          case "FIC Cooking":
            columns.push({
              data: "FIC Cooking",
              title: "FIC: Cooking",
            });
            break;
          case "FIC Condition":
            columns.push({
              data: "FIC Condition",
              title: "FIC: Condition",
            });
            break;
          case "FIC CompASB":
            columns.push({
              data: "FIC CompASB",
              title: "FIC: CompASB",
            });
            break;
          case "FIC Travel":
            columns.push({
              data: "FIC Travel",
              title: "FIC: Travel",
            });
            break;
          case "GOID":
            columns.push({
              data: "GOID",
              title: "DI: GOID",
            });
            break;
          case "Age":
            columns.push({
              data: "Age",
              title: "DI: Age",
            });
            break;
          case "Gender":
            columns.push({
              data: "Gender",
              title: "DI: Gender",
            });
            break;
          case "Race":
            columns.push({
              data: "Race",
              title: "DI: Race",
            });
            break;
          case "Language":
            columns.push({
              data: "Language",
              title: "DI: Language",
            });
            break;
          case "angler_zip_code":
            columns.push({
              data: "angler_zip_code",
              title: "DI: Zip Code",
            });
            break;
          case "Accessibility_Improvement":
            columns.push({
              data: "Accessibility_Improvement",
              title: "DI: Accessibility Improvement",
            });
            break;
          case "submission_date":
            columns.push({
              data: "submission_date",
              title: "Submission Date",
            });
            break;
          case "device_used":
            columns.push({
              data: "device_used",
              title: "Device Used",
            });
            break;
          case "survey_duration":
            columns.push({
              data: "survey_duration",
              title: "Survey Duration",
            });
            break;
          default:
            break;
        }
      });
    }

    $(datatableRef.current).DataTable({
      data: data,
      scrollX: true,
      autoWidth: false,
      colReorder: true,
      lengthMenu: [
        [10, 25, 50, -1],
        [10, 25, 50, "All"],
      ],
      pageLength: 10,
      columns: columns,
      order: [1, "asc"],
      ...(searchBuilder && {
        layout: {
          top: {
            searchBuilder: {
              preDefined: {
                criteria: [
                  {
                    condition: "=",
                    data: "Region",
                    value: [""],
                  },
                ],
                logic: "OR",
              },
            },
          },
        },
      }),
      ...(exportButtons && {
        layout: {
          top1End: {
            buttons: [
              {
                extend: "copy",
                title: null,
              },
              "csv",
              {
                extend: "excelHtml5",
                title: null,
                filename:
                  "CIRAS Extract - " +
                  moment(new Date()).format("yyyy-MM-DD hh.mm.ss"),
                customize: function (xlsx) {
                  $(xlsx.xl["styles.xml"])
                    .find('numFmt[numFmtId="164"]')
                    .attr("formatCode", "[$$-45C] #,##0.00_-");
                },
              },
              {
                extend: "print",
                title: "CIRAS Extract",
              },
            ],
          },
        },
      }),
    });
  }, [data, exportButtons, page, searchBuilder]);

  return (
    <div className="ciras-datatable">
      <>
        <h3>Results</h3>
        <table className="display" ref={datatableRef} />
      </>
    </div>
  );
};

export default Datatable;
