import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import config from "../config";

const url = config.API;

async function getHeaders() {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });
  const accessToken = response.accessToken;

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  return headers;
}

export const fetchFromAPI = async (proc) => {
  const headers = await getHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      StoredProcedureName: proc,
    }),
  });
  return response.json();
};

export const fetchFromAPIWithObj = async (proc, obj) => {
  const headers = await getHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      StoredProcedureName: proc,
      Payload: obj ? obj : {},
    }),
  });
  return response.json();
};

export const fetchSurveyFromAPI = async (proc, id) => {
  const headers = await getHeaders();
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      StoredProcedureName: proc,
      Payload: { SurveyID: id },
    }),
  });
  return response.json();
};

export const hasUserRoles = () => isAdminRead() || isAdminWrite();

export const isAdminWrite = () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return false;
  } else {
    return account.idTokenClaims?.roles?.includes("admin.write");
  }
};

export const isAdminRead = () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return false;
  } else {
    return account.idTokenClaims?.roles?.includes("admin.read");
  }
};

export const getUserDisplayName = () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return false;
  } else {
    return account.name;
  }
};
