import { useEffect, useState, useMemo } from "react";
import { fetchFromAPI, fetchFromAPIWithObj } from "../../utils/hooks.js";
import Datatable from "../Datatable.js";
import { Link } from "react-router-dom";
import { PAGE } from "../../utils/globals";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import surveyTheme from "survey-core/themes/plain-light-panelless";
import FilterQuestions from "../../survey/filterQuestions.js";
import Loader from "../Loader.js";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

function SearchViewSurveysPage() {
  const [isLoading, setLoading] = useState(true);

  const [responseData, setResponseData] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);

  useEffect(() => {
    fetchFromAPI("FilterOptions")
      .then((FilterOptions) => {
        if (FilterOptions.success) {
          setFilterOptions(FilterOptions);
        }
        setLoading(false);
      })
      .catch((error) => console.log("Error", error));
  }, []);

  const survey = useMemo(() => {
    const surveyJson = FilterQuestions({
      data: filterOptions,
    });
    return new Model(surveyJson);
  }, [filterOptions]);

  survey.applyTheme(surveyTheme);
  survey.showNavigationButtons = false;

  const submitSurvey = () => {
    let surveyData = survey
      .getPlainData()
      .filter((item) => !["dateToggle"].includes(item.name))
      .map(({ title, displayValue, isNode, data, ...rest }) => rest);

    const columnFilters = surveyData.find(
      (item) => item.name === "ColumnFilters"
    );

    if (columnFilters.value) {
      const transformedData = Object.entries(columnFilters.value).map(
        ([name, value]) => {
          const colValue = value.Value;
          if (colValue.length === 1) {
            return { name, value: colValue[0] };
          } else {
            return { name };
          }
        }
      );
      surveyData = surveyData.concat(transformedData);
    }
    surveyData = surveyData.filter(
      (item) => !["ColumnFilters"].includes(item.name)
    );

    if (survey.validate(true, true)) {
      setLoading(true);
      fetchFromAPIWithObj("SelectSurveys", surveyData).then((response) => {
        console.log("Response", response.result.data);
        setResponseData(response.result.data);
        setLoading(false);
      });
    }
  };

  return (
    <div className="section collapsed">
      <div className="container">
        <ol className="breadcrumb">
          <li>
            <Link to={PAGE.HOME.URL}>{PAGE.HOME.TITLE}</Link>
          </li>
          <li className="active">{PAGE.SEARCH_AND_VIEW_SURVEYS.TITLE}</li>
        </ol>
        <div className="page-title">
          <h1>CIRAS - {PAGE.SEARCH_AND_VIEW_SURVEYS.TITLE}</h1>
          {!filterOptions ? (
            <Loader />
          ) : (
            <>
              <Survey model={survey} showCompletedPage={false} />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="contained" onClick={submitSurvey}>
                  Apply
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    survey.clear();
                    survey.render();
                    submitSurvey();
                  }}
                >
                  Reset
                </Button>
              </Stack>
              {isLoading ? (
                <Loader />
              ) : (
                <Datatable
                  data={responseData}
                  page={PAGE.SEARCH_AND_VIEW_SURVEYS.URL}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchViewSurveysPage;
